.processing-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .6);
  z-index: 5000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.processing-overlay-inner {
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}
