.transaction-overview-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  margin: 0 -10px;
  padding: 0 10px;

  text-decoration: none !important;
  color: inherit !important;

  &:hover {
    background-color: rgba(0,0,0,.075);
  }

  &:not(:last-child) {
    border-bottom: .5px solid darken(rgba(0,0,0,.075), .2);
  }
}


.transaction-overview-item-wrapper {
  &:not(:last-child) {
    border-bottom: .5px solid darken(rgba(0,0,0,.075), .2);
  }
}
